<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import TableView from '@components/elements/TableView';

export default {
    page: {
        title: 'Invited Users'
    },

    extends: TableView,

    data() {
        return {
            pageTitle: 'Invited Users',
            eventId: null,
            activeTab: { value: 'all' }
        };
    },

    computed: {
        labels() {
            return [
                {
                    value: 'user.email',
                    text: 'Email',
                    width: '200px'
                },
                {
                    value: 'user.first_name',
                    text: 'First Name',
                    width: '180px'
                },
                {
                    value: 'user.last_name',
                    text: 'Last Name',
                    width: '180px'
                },
                {
                    value: 'user.phone',
                    text: 'Phone',
                    width: '200px'
                },
                {
                    value: 'user.speciality',
                    text: 'Speciality',
                    width: '200px'
                },
                {
                    value: 'user.medical_institute',
                    text: 'Medical Institute',
                    width: '200px'
                },
                {
                    value: 'user.city',
                    text: 'City',
                    width: '200px'
                },
                {
                    value: 'status',
                    width: '200px'
                }
            ];
        },

        tabsElements() {
            return [
                { value: 'all', text: 'All' },
                { value: 'approved', text: 'Approved' },
                { value: 'pending', text: 'Pending' }
            ];
        },

        additionalFetchData() {
            return {
                id: this.$route.params.eventId,
                status: this.activeTab.value
            };
        },

        globalActions() {
            return this.activeTab.value === 'all'
                ? [
                      {
                          icon: 'fas fa-download',
                          name: 'Download users information',
                          method: 'generateCSVFile'
                      }
                  ]
                : [];
        },

        includeDeleteAction() {
            return false;
        },

        options() {
            return { addButton: false };
        }
    },

    async mounted() {
        this.eventId = this.$route.params.eventId;
    },

    methods: {
        ...mapActions({
            getItems: 'events/getInvitedUsers',
            csvExport: 'events/csvExport'
        }),

        async generateCSVFile() {
            try {
                const content = await this.csvExport(this.eventId);
                const fileURL = window.URL.createObjectURL(
                    new Blob([`\ufeff${content}`])
                );

                this.downloadFile(fileURL);
            } catch (error) {
                console.error(error);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        downloadFile(url) {
            const fileName = `event_participants_${moment().format(
                'YYYY-MM-DD_HH-mm-ss'
            )}.csv`;

            this.$downloadFile(url, fileName);
        }
    }
};
</script>
